export const DISCLAIMER_VERSION_NBR = '6';
export const militaryDrop = {
	101: [
		{ value: '3', viewValue: 'Not Applicable' },
		{ value: '4', viewValue: "I don't wish to provide" },
		{ value: '5', viewValue: 'Previously served, Veteran' },
		{ value: '6', viewValue: 'Previously served, Retired' },
		{ value: '7', viewValue: 'Currently Serving' }
	],
	102: [
		{ value: '3', viewValue: 'No corresponde' },
		{ value: '4', viewValue: 'No deseo suministrar' },
		{ value: '5', viewValue: 'Veterano que ha prestado servicio' },
		{ value: '6', viewValue: 'Sirvió anteriormente, Retirado' },
		{ value: '7', viewValue: 'En servicio actualmente' }
	]
};

// SS Stands for Self Schedule
const SS_INVITE_TO_PRE_SCREEN_ACTION_CODE = 24;
const SS_PRE_SCREEN_SCHEDULED_ACTION_CODE = 26;
const SS_INVITE_TO_SCHEDULE_ACTION_CODE = 34;
const SS_APPLICANT_SCHEDULED_ACTION_CODE = 36;
const SS_HRM_SCHEDULED_ACTION_CODE = 37;
const SS_APPLICANT_RE_SCHEDULED_ACTION_CODE = 38;
const SS_EVENT_RE_SCHEDULED_ACTION_CODE = 42;
const SS_INVITE_TO_RE_SCHEDULE_ACTION_CODE = 44;

export const schedulableActionTypeCodes = [
	SS_INVITE_TO_PRE_SCREEN_ACTION_CODE,
	SS_PRE_SCREEN_SCHEDULED_ACTION_CODE,
	SS_INVITE_TO_SCHEDULE_ACTION_CODE,
	SS_APPLICANT_SCHEDULED_ACTION_CODE,
	SS_HRM_SCHEDULED_ACTION_CODE,
	SS_APPLICANT_RE_SCHEDULED_ACTION_CODE,
	SS_EVENT_RE_SCHEDULED_ACTION_CODE,
	SS_INVITE_TO_RE_SCHEDULE_ACTION_CODE
];

export const selfScheduleEventCodeMap = {
	SC_INTERVIEW_EVENT_CODE: 1,
	SC_ORIENTATION_EVENT_CODE_1: 14,
	SC_ORIENTATION_EVENT_CODE_2: 2,
	SC_RJPT_EVENT_CODE: 12,
	SC_FT_EVENT_CODE: 13,
	SH_PRESCREEN_EVENT_CODE: 11
};

export const pathMap = {
	'location-search': {
		icon: ['back', 'icon'],
		desc: ['back', 'applicationOverview']
	},
	'job-search': {
		icon: 'back',
		desc: 'backToLoc'
	},
	'job-details': {
		icon: 'back',
		desc: 'backToJob'
	},
	availability: {
		icon: 'back',
		desc: 'backToJob'
	},
	'create-account': {
		icon: 'back',
		desc: 'back'
	},
	'about-you': {
		icon: 'back',
		desc: ''
	},
	'applied-job-tracker': {
		icon: 'back',
		desc: 'backToDash'
	},
	'edit-profile-job-history': {
		icon: 'back',
		desc: ''
	},
	'edit-profile-certificates-licenses': {
		icon: 'back',
		desc: ''
	}
};

export const feedback = {
	'post-apply-dashboard': true
};

export const shiftTimings = [
	'12:00 am',
	'12:30 am',
	'1:00 am',
	'1:30 am',
	'2:00 am',
	'2:30 am',
	'3:00 am',
	'3:30 am',
	'4:00 am',
	'4:30 am',
	'5:00 am',
	'5:30 am',
	'6:00 am',
	'6:30 am',
	'7:00 am', //14
	'7:30 am',
	'8:00 am',
	'8:30 am',
	'9:00 am', //18
	'9:30 am',
	'10:00 am',
	'10:30 am',
	'11:00 am', //22
	'11:30 am',
	'12:00 pm',
	'12:30 pm',
	'1:00 pm', //26
	'1:30 pm',
	'2:00 pm',
	'2:30 pm',
	'3:00 pm',
	'3:30 pm',
	'4:00 pm', //32
	'4:30 pm',
	'5:00 pm',
	'5:30 pm',
	'6:00 pm',
	'6:30 pm',
	'7:00 pm',
	'7:30 pm',
	'8:00 pm', //40
	'8:30 pm',
	'9:00 pm',
	'9:30 pm',
	'10:00 pm',
	'10:30 pm',
	'11:00 pm', //46
	'11:30 pm'
];
export const shiftTimingsOpeneing = {
	startTime: 8,
	endTime: 14
};
export const shiftTimingsMorning = {
	startTime: 14,
	endTime: 18
};
export const shiftTimingsMidShift = {
	startTime: 16,
	endTime: 22
};
export const shiftTimingsClosing = {
	startTime: 26,
	endTime: 35
};
export const shiftTimingsOvernight = {
	startTime: 35,
	endTime: 46
};

export const SSN_LENGTH_TO_VALIDATE = 8

export const FULL_TIME_MIN_HOURS=8;
export const FULL_TIME_MAX_HOURS=24;

export const studentType = [
	{
		119: 'High school student',
		120: 'College or trade school student',
		118: 'No'
	},
	{
		119: 'Si, soy estudiante de secundaria',
		120: 'Sí, soy un estudiante universitario o de comercio',
		118: 'No soy estudiante'
	}
];
export const ageCrit = [
	{
		93: 'Less than 16 years of age',
		94: '16 through 17 years of age',
		95: '18 years of age or older'
	},
	{ 93: 'Menos de 16 años de edad', 94: 'Entre 16 y 17 años de edad', 95: '18 años de edad o más' }
];

export const industryDetails = {
	101: [
		{ value: '1', viewValue: 'RETAIL, GENERAL' },
		{ value: '2', viewValue: 'RETAIL, GROCERY' },
		{ value: '3', viewValue: 'RETAIL, SOFT GOODS' },
		{ value: '4', viewValue: 'RETAIL, HARD GOODS' },
		{ value: '5', viewValue: 'RETAIL, SPECIALTY' },
		{ value: '6', viewValue: 'MANUFACTURING' },
		{ value: '7', viewValue: 'TRANSPORTATION' },
		{ value: '8', viewValue: 'GOVERNMENT/MILITARY' },
		{ value: '9', viewValue: 'EDUCATION' },
		{ value: '10', viewValue: 'NON-PROFIT' },
		{ value: '11', viewValue: 'INFORMATION TECHNOLOGY' },
		{ value: '12', viewValue: 'TELECOMMUNICATIONS' },
		{ value: '13', viewValue: 'SERVICE' },
		{ value: '99', viewValue: 'OTHER' },
		{ value: '100', viewValue: 'WAREHOUSING' }
	],
	102: [
		{ value: '1', viewValue: 'MINORISTA GENERAL' },
		{ value: '2', viewValue: 'MINORISTA DE COMESTIBLES' },
		{ value: '3', viewValue: 'COMERCIO MINORISTA, PRODUCTOS BLANDOS' },
		{ value: '4', viewValue: 'COMERCIO MINORISTA, PRODUCTOS DUROS' },
		{ value: '5', viewValue: 'MINORISTA, ESPECIALIDAD' },
		{ value: '6', viewValue: 'FABRICACIÓN' },
		{ value: '7', viewValue: 'TRANSPORTE' },
		{ value: '8', viewValue: 'GOBIERNO / MILITAR' },
		{ value: '9', viewValue: 'EDUCACIÓN' },
		{ value: '10', viewValue: 'ORGANIZACIÓN SIN ÁNIMO DE LUCRO' },
		{ value: '11', viewValue: 'TECNOLOGÍAS DE LA INFORMACIÓN' },
		{ value: '12', viewValue: 'TELECOMUNICACIONES' },
		{ value: '13', viewValue: 'SERVICIO' },
		{ value: '99', viewValue: 'OTRO' },
		{ value: '100', viewValue: 'ALMACENAJE' }
	]
};

export const GTM = {
	EVENTS: {
		EVENT: 'event',
		GUEST_JOB_SEARCH: 'guest-job-search',
		APPLICANT_JOB_SEARCH: 'applicant-job-search',
		LOGIN_BUTTON: 'homepage-login-button-clicked',
		CREATE_ACCOUNT_SUCCESS: 'create-account-success',
		APPLICATION_RENEW: 'application-renew',
		LANGUAGE_TOGGLE: 'language-toggle-clicked',
		JOB_OPENING_VIEW_DETAILS: 'job-opening-view-details-clicked',
		JOB_SUBMIT_BUTTON: 'job-submit-button-clicked',
		TAKE_ASSESSMENT: 'take-assessment-clicked',
		//New
		LAND_ON_CREATE_ACCOUNT: 'create-account-screen',
		SAVE_FOR_LATER: 'save-for-later-clicked',
		APPLICATION_STEP1_ABOUT_YOU: 'application-step1-about-you',
		NOT_ELIGIBLE_VISA_DOCUMENT_REQUIREMENT: 'not-eligible-visa-doc',
		APPLICATION_STEP2_EEO: 'application-step2-EEO',
		APPLICATION_STEP3_EXPERIENCE: 'application-step3-experience',
		APPLICATION_STEP4_EMPLOYMENT_HISTORY: 'application-step4-employment-history',
		APPLICATION_STEP5_WOTC: 'application-step5-wotc',
		APPLICATION_SUBMITTED: 'application-submitted',
		VIEW_POST_APPLY_DASHBOARD: 'view-post-apply-dashboard',
		WITHDRAW: 'withdraw-self-disposition',
		SUBMIT_FEEDBACK: 'submit-feedback',
		//Metric
		EDIT_PROFILE_FIRST_NAME: 'edit-profile-first-name',
		EDIT_PROFILE_LAST_NAME: 'edit-profile-last-name',
		EDIT_PROFILE_PREF_FIRST_NAME: 'edit-profile-pref-first-name',
		EDIT_PROFILE_PREF_LAST_NAME: 'edit-profile-pref-last-name',
		EDIT_PROFILE_ZIP: 'edit-profile-zip',
		EDIT_PROFILE_MOBILE: 'edit-profile-mobile',
		EDIT_PROFILE_ALT_CONTACT: 'edit-profile-alt-contact',
		EDIT_PROFILE_OPT_IN_TEXT: 'edit-profile-opt-in-text'
	},
	VARIABLES: {
		GUEST_MODE: 'guest-mode'
	}
};
export const descriptions = {
	101: [
		{ value: '1', viewValue: 'Contact Lens Licensed Dispensing Optician' },
		{ value: '2', viewValue: 'Spectacle Licensed Dispensing Optician' },
		{ value: '3', viewValue: 'Licensed Dispensing Optician' },
		{ value: '4', viewValue: 'American Board of Opticianry Certification' },
		{ value: '5', viewValue: 'National Contact Lens Examiner Certification' },
		{ value: '6', viewValue: 'Commercial Drivers License' },
		{ value: '7', viewValue: 'Commercial Drivers License with HazMat Endorsement' },
		{ value: '8', viewValue: 'Drivers Medical Certification' },
		{ value: '9', viewValue: 'Fork Lift Certification' },
		{ value: '10', viewValue: 'Power Equipment License' },
		{ value: '11', viewValue: 'Ammonia Certification HazMat FIT test' },
		{ value: '12', viewValue: 'RISK Reorientation' },
		{ value: '13', viewValue: 'SIRT CPR Certification' },
		{ value: '14', viewValue: 'Seafood HACCP Certification' },
		{ value: '15', viewValue: 'Supersafe Mark Food Safety Certification' },
		{ value: '16', viewValue: 'DOT Hazardous Waste Certification' },
		{ value: '17', viewValue: 'Brake Certification' },
		{ value: '18', viewValue: 'Air Respiratory Certification' },
		{ value: '19', viewValue: 'Food Manager Certification' },
		{ value: '20', viewValue: 'GIA Gemologist' },
		{ value: '21', viewValue: 'Certified Assistant Refrigeration Operator Certificate' },
		{ value: '22', viewValue: 'Certified Industrial Refrigeration Operator Certificate' },
		{ value: '23', viewValue: 'Hazardous Material Certification' },
		{ value: '24', viewValue: 'PTCB Certified Pharmacy Technician' },
		{ value: '25', viewValue: 'ExCPT Certified Pharmacy Technician' },
		{ value: '26', viewValue: 'State Certified Registered or Licensed Pharmacy Technician' },
		{ value: '27', viewValue: 'State Registered Pharmacist' },
		{ value: '28', viewValue: 'ABO' },
		{ value: '29', viewValue: 'Optician License' }
	],

	102: [
		{ value: '1', viewValue: 'Técnico óptico licenciado en lentes de contacto' },
		{ value: '2', viewValue: 'Técnico óptico licenciado en anteojos' },
		{ value: '3', viewValue: 'Técnico óptico licenciado' },
		{ value: '4', viewValue: 'Certificación del Consejo Estadounidense de Ópticos' },
		{
			value: '5',
			viewValue: 'Certificación del Consejo Nacional de Expertos en Lentes de Contacto'
		},
		{ value: '6', viewValue: 'Licencia de conducir comercial' },
		{
			value: '7',
			viewValue: 'Licencia de conducir comercial con permiso para materiales peligrosos'
		},
		{ value: '8', viewValue: 'Certificado médico de conductor' },
		{ value: '9', viewValue: 'Certificado de conductor de montacargas' },
		{ value: '10', viewValue: 'Licencia para equipos electrónicos' },
		{
			value: '11',
			viewValue: 'Prueba de ajuste de materiales peligrosos con certificación para amoníaco'
		},
		{ value: '12', viewValue: 'Reorientación del riesgo' },
		{
			value: '13',
			viewValue: 'Certificación para RCP y del Equipo de respuesta a lesiones graves'
		},
		{
			value: '14',
			viewValue:
				'Certificación de análisis de peligros y puntos críticos de control para pescados y mariscos'
		},
		{ value: '15', viewValue: 'Certificación para seguridad de los alimentos Supersafe Mark' },
		{
			value: '16',
			viewValue: 'Certificación para desechos peligrosos del Departamento del Trabajo'
		},
		{ value: '17', viewValue: 'Certificación para frenos' },
		{ value: '18', viewValue: 'Certificación para protección respiratoria' },
		{ value: '19', viewValue: 'Certificación para gerente de seguridad alimentaria' },
		{ value: '20', viewValue: 'Gemólogo del Instituto Gemológico de América' },
		{ value: '21', viewValue: 'Operador auxiliar de equipos de refrigeración certificado' },
		{ value: '22', viewValue: 'Operador de equipos de refrigeración industrial certificado' },
		{ value: '23', viewValue: 'Certificación para materiales peligrosos' },
		{
			value: '24',
			viewValue:
				'Técnico de farmacia certificado por el Consejo de Certificación de Técnicos de Farmacia'
		},
		{
			value: '25',
			viewValue:
				'Técnico de farmacia certificado por el Examen de Certificación de Técnicos de Farmacia'
		},
		{
			value: '26',
			viewValue: 'Técnico de farmacia licenciado o registrado y certificado por el estado'
		},
		{ value: '27', viewValue: 'Farmacéutico registrado en el estado' },
		{ value: '28', viewValue: 'Consejo Estadounidense de Ópticos (ABO)' },
		{ value: '29', viewValue: 'Licencia de óptico' }
	]
};

export const allStates = [
	{ value: 'AL', viewValue: 'Alabama' },
	{ value: 'AK', viewValue: 'Alaska' },
	{ value: 'AZ', viewValue: 'Arizona' },
	{ value: 'AR', viewValue: 'Arkansas' },
	{ value: 'CA', viewValue: 'California' },
	{ value: 'CO', viewValue: 'Colorado' },
	{ value: 'CT', viewValue: 'Connecticut' },
	{ value: 'DC', viewValue: 'Dist. of Columbia' },
	{ value: 'DE', viewValue: 'Delaware' },
	{ value: 'FL', viewValue: 'Florida' },
	{ value: 'GA', viewValue: 'Georgia' },
	{ value: 'HI', viewValue: 'Hawaii' },
	{ value: 'ID', viewValue: 'Idaho' },
	{ value: 'IL', viewValue: 'Illinois' },
	{ value: 'IN', viewValue: 'Indiana' },
	{ value: 'IA', viewValue: 'Iowa' },
	{ value: 'KS', viewValue: 'Kansas' },
	{ value: 'KY', viewValue: 'Kentucky' },
	{ value: 'LA', viewValue: 'Louisiana' },
	{ value: 'ME', viewValue: 'Maine' },
	{ value: 'MD', viewValue: 'Maryland' },
	{ value: 'MA', viewValue: 'Massachusetts' },
	{ value: 'MI', viewValue: 'Michigan' },
	{ value: 'MN', viewValue: 'Minnesota' },
	{ value: 'MS', viewValue: 'Mississippi' },
	{ value: 'MO', viewValue: 'Missouri' },
	{ value: 'MT', viewValue: 'Montana' },
	{ value: 'NE', viewValue: 'Nebraska' },
	{ value: 'NV', viewValue: 'Nevada' },
	{ value: 'NH', viewValue: 'New Hampshire' },
	{ value: 'NJ', viewValue: 'New Jersey' },
	{ value: 'NM', viewValue: 'New Mexico' },
	{ value: 'NY', viewValue: 'New York' },
	{ value: 'NC', viewValue: 'North Carolina' },
	{ value: 'ND', viewValue: 'North Dakota' },
	{ value: 'OH', viewValue: 'Ohio' },
	{ value: 'OK', viewValue: 'Oklahoma' },
	{ value: 'OR', viewValue: 'Oregon' },
	{ value: 'PA', viewValue: 'Pennsylvania' },
	{ value: 'PR', viewValue: 'Puerto Rico' },
	{ value: 'RI', viewValue: 'Rhode Island' },
	{ value: 'SC', viewValue: 'South Carolina' },
	{ value: 'SD', viewValue: 'South Dakota' },
	{ value: 'TN', viewValue: 'Tennessee' },
	{ value: 'TX', viewValue: 'Texas' },
	{ value: 'UT', viewValue: 'Utah' },
	{ value: 'VT', viewValue: 'Vermont' },
	{ value: 'VA', viewValue: 'Virginia' },
	{ value: 'WA', viewValue: 'Washington' },
	{ value: 'WV', viewValue: 'West Virginia' },
	{ value: 'WI', viewValue: 'Wisconsin' },
	{ value: 'WY', viewValue: 'Wyoming' }
];

export const states = {
	101: [
		{ value: 'NA', viewValue: 'Not Applicable' },
		{ value: 'OT', viewValue: 'Other' }
	],
	102: [
		{ value: 'NA', viewValue: 'No corresponde' },
		{ value: 'OT', viewValue: 'Otro' }
	]
};

export const issuedby = {
	101: [
		{ value: '153', viewValue: 'National' },
		{ value: '154', viewValue: 'State' }
	],
	102: [
		{ value: '153', viewValue: 'Nacional' },
		{ value: '154', viewValue: 'Estado' }
	]
};

export const countries = {
	101: [
		{ value: 'NA', viewValue: 'Not Applicable' },
		{ value: 'US', viewValue: 'United States' },
		{ value: 'OT', viewValue: 'Other' }
	],
	102: [
		{ value: 'NA', viewValue: 'No corresponde' },
		{ value: 'US', viewValue: 'Los Estados Unidos' },
		{ value: 'OT', viewValue: 'Otro' }
	]
};

export const categoryDetails = [
	{
		catg_code: '8',
		catg_desc: {
			101: 'APPAREL / ACCESSORIES',
			102: 'Ropa y Accesorios'
		},
		subCategoryList: [
			{
				subcatg_code: '81',
				subcatg_desc: {
					101: 'Apparel / Shoes',
					102: 'Ropa / Calzados'
				}
			},
			{
				subcatg_code: '82',
				subcatg_desc: {
					101: 'Jewelry',
					102: 'Joyería'
				}
			}
		]
	},
	{
		catg_code: '3',
		catg_desc: {
			101: 'CUSTOMER SERVICE',
			102: 'Servicio al Cliente'
		},
		subCategoryList: [
			{
				subcatg_code: '33',
				subcatg_desc: {
					101: 'Cart Collector / People Greeter',
					102: 'Asociado Carros de Compra / Asociado de Bienvenida'
				}
			},
			{
				subcatg_code: '31',
				subcatg_desc: {
					101: 'Cashier',
					102: 'Cajero'
				}
			},
			{
				subcatg_code: '32',
				subcatg_desc: {
					101: 'Customer Relations',
					102: 'Servicio al Cliente'
				}
			}
		]
	},
	{
		catg_code: '6',
		catg_desc: {
			101: 'ENTERTAINMENT / HOBBY',
			102: 'Entretenimiento / Pasatiempo'
		},
		subCategoryList: [
			{
				subcatg_code: '61',
				subcatg_desc: {
					101: 'Crafts / Fabrics',
					102: 'Manualidades / Telas'
				}
			},
			{
				subcatg_code: '62',
				subcatg_desc: {
					101: 'Electronics',
					102: 'Electrónica'
				}
			},
			{
				subcatg_code: '67',
				subcatg_desc: {
					101: 'Pets',
					102: 'Mascotas'
				}
			},
			{
				subcatg_code: '65',
				subcatg_desc: {
					101: 'Photo',
					102: 'Fotografía'
				}
			},
			{
				subcatg_code: '63',
				subcatg_desc: {
					101: 'Sporting Goods',
					102: 'Deportes'
				}
			},
			{
				subcatg_code: '64',
				subcatg_desc: {
					101: 'Stationery / Books',
					102: 'Escolar / Oficina / Libros'
				}
			},
			{
				subcatg_code: '66',
				subcatg_desc: {
					101: 'Toys',
					102: 'Juguetes'
				}
			}
		]
	},
	{
		catg_code: '1',
		catg_desc: {
			101: 'FACILITY MANAGEMENT',
			102: 'Gestión de instalaciones'
		},
		subCategoryList: [
			{
				subcatg_code: '12',
				subcatg_desc: {
					101: 'Facility Manager',
					102: 'Gerente de Tienda'
				}
			},
			{
				subcatg_code: '13',
				subcatg_desc: {
					101: 'Secondary Facility Management',
					102: 'Co-Manager'
				}
			},
			{
				subcatg_code: '11',
				subcatg_desc: {
					101: 'Supervisor',
					102: 'Supervisor'
				}
			},
			{
				subcatg_code: '14',
				subcatg_desc: {
					101: 'Vision Center Manager (VCM)',
					102: 'Gerente de Óptica'
				}
			}
		]
	},
	{
		catg_code: '2',
		catg_desc: {
			101: 'GENERAL OPERATIONS',
			102: 'Operaciones Generales'
		},
		subCategoryList: [
			{
				subcatg_code: '22',
				subcatg_desc: {
					101: 'Asset Protection / Loss Prevention',
					102: 'Proteción de Activos / Prevención de Perdidas'
				}
			},
			{
				subcatg_code: '21',
				subcatg_desc: {
					101: 'Human Resources / Personnel',
					102: 'Recursos Humanos / Personal'
				}
			},
			{
				subcatg_code: '25',
				subcatg_desc: {
					101: 'Maintenance / Custodial',
					102: 'Mantenimiento'
				}
			},
			{
				subcatg_code: '23',
				subcatg_desc: {
					101: 'Office',
					102: 'Mantenimiento'
				}
			},
			{
				subcatg_code: '24',
				subcatg_desc: {
					101: 'Receiving / Warehouse',
					102: 'Recibo / Almacén'
				}
			}
		]
	},
	{
		catg_code: '4',
		catg_desc: {
			101: 'GROCERY / FOOD',
			102: 'Supermercado / Comida'
		},
		subCategoryList: [
			{
				subcatg_code: '41',
				subcatg_desc: {
					101: 'Bakery / Bread',
					102: 'Bakery / Pan'
				}
			},
			{
				subcatg_code: '42',
				subcatg_desc: {
					101: 'Grocery / General Merchandise',
					102: 'Grocery / Mercancía Gerneral'
				}
			},
			{
				subcatg_code: '43',
				subcatg_desc: {
					101: 'Meat / Deli / Seafood',
					102: 'Carnes / Deli / Mariscos'
				}
			},
			{
				subcatg_code: '44',
				subcatg_desc: {
					101: 'Produce',
					102: 'Verduras y Vegetales'
				}
			}
		]
	},
	{
		catg_code: '7',
		catg_desc: {
			101: 'HOME / AUTOMOTIVE',
			102: 'Casa / Automotriz'
		},
		subCategoryList: [
			{
				subcatg_code: '73',
				subcatg_desc: {
					101: 'Automotive',
					102: 'Automovil'
				}
			},
			{
				subcatg_code: '75',
				subcatg_desc: {
					101: 'Floral',
					102: 'Floristería'
				}
			},
			{
				subcatg_code: '74',
				subcatg_desc: {
					101: 'Hardware',
					102: 'Ferretería'
				}
			},
			{
				subcatg_code: '71',
				subcatg_desc: {
					101: 'Home Furnishings',
					102: 'Enseres para el Hogar'
				}
			},
			{
				subcatg_code: '72',
				subcatg_desc: {
					101: 'Lawn & Garden',
					102: 'Jardinería'
				}
			}
		]
	},
	{
		catg_code: '10',
		catg_desc: {
			101: 'MILITARY EXPERIENCE',
			102: 'Experiencia Militar'
		},
		subCategoryList: [
			{
				subcatg_code: '102',
				subcatg_desc: {
					101: 'Military - Air Force',
					102: 'Fuerzas Armadas - Air Force'
				}
			},
			{
				subcatg_code: '101',
				subcatg_desc: {
					101: 'Military - Army',
					102: 'Fuerzas Armadas - Army'
				}
			},
			{
				subcatg_code: '105',
				subcatg_desc: {
					101: 'Military - Coast Guard',
					102: 'Fuerzas Armadas - Coast Guard'
				}
			},
			{
				subcatg_code: '104',
				subcatg_desc: {
					101: 'Military - Marine Corps',
					102: 'Fuerzas Armadas - Marines Corps'
				}
			},
			{
				subcatg_code: '103',
				subcatg_desc: {
					101: 'Military - Navy',
					102: 'Fuerzas Armadas - Navy'
				}
			}
		]
	},
	{
		catg_code: '5',
		catg_desc: {
			101: 'VISION/PHARMACY/PERSONAL',
			102: 'Óptica / Farmacia / Personal'
		},
		subCategoryList: [
			{
				subcatg_code: '56',
				subcatg_desc: {
					101: 'Health & Beauty Aids (HBA)',
					102: 'Salud / Belleza'
				}
			},
			{
				subcatg_code: '54',
				subcatg_desc: {
					101: 'Licensed Optician',
					102: 'Licensiado Óptico'
				}
			},
			{
				subcatg_code: '51',
				subcatg_desc: {
					101: 'Pharmacy (General)',
					102: 'Farmacia'
				}
			},
			{
				subcatg_code: '52',
				subcatg_desc: {
					101: 'Pharmacy Technician',
					102: 'Técnico de Farmacia'
				}
			},
			{
				subcatg_code: '53',
				subcatg_desc: {
					101: 'Vision (General)',
					102: 'Óptica'
				}
			},
			{
				subcatg_code: '55',
				subcatg_desc: {
					101: 'Vision Center Manager (VCM)',
					102: 'Gerente de Óptica'
				}
			}
		]
	}
];
// CP Shifts
const OPENING_SHIFT_ID = 10;
const MORNING_SHIFT_ID = 11;
const MID_SHIFT_SHIFT_ID = 12;
const CLOSING_SHIFT_ID = 13;
const OVERNIGHT_SHIFT_ID = 14;
const FLEX_SHIFT_ID = 17;
const FLEX_SHIFT_ID_PR = 18;

const SC_US_WEEKDAY_1_ST_SHIFT_ID = 1;
const SC_US_WEEKDAY_2_ND_SHIFT_ID = 2;
const SC_US_WEEKDAY_3_RD_SHIFT_ID = 3;
const SC_US_WEEKEND_4_TH_SHIFT_ID = 4;
const SC_US_WEEKEND_5_TH_SHIFT_ID = 5;
const SC_US_WEEKEND_6_TH_SHIFT_ID = 6;

const SC_PR_1_ST_SHIFT_ID = 7;
const SC_PR_2_ND_SHIFT_ID = 8;
const SC_PR_3_RD_SHIFT_ID = 9;
export const EMPL_HIST_COMPLETED = 250;
	
export const shiftIds = {
	OPENING_SHIFT_ID: OPENING_SHIFT_ID,
	MORNING_SHIFT_ID: MORNING_SHIFT_ID,
	MID_SHIFT_SHIFT_ID: MID_SHIFT_SHIFT_ID,
	CLOSING_SHIFT_ID: CLOSING_SHIFT_ID,
	OVERNIGHT_SHIFT_ID: OVERNIGHT_SHIFT_ID,
	FLEX_SHIFT_ID: FLEX_SHIFT_ID,
	FLEX_SHIFT_ID_PR: FLEX_SHIFT_ID_PR,
	SC_US_WEEKDAY_1_ST_SHIFT_ID: SC_US_WEEKDAY_1_ST_SHIFT_ID,
	SC_US_WEEKDAY_2_ND_SHIFT_ID: SC_US_WEEKDAY_2_ND_SHIFT_ID,
	SC_US_WEEKDAY_3_RD_SHIFT_ID: SC_US_WEEKDAY_3_RD_SHIFT_ID,
	SC_US_WEEKEND_4_TH_SHIFT_ID: SC_US_WEEKEND_4_TH_SHIFT_ID,
	SC_US_WEEKEND_5_TH_SHIFT_ID: SC_US_WEEKEND_5_TH_SHIFT_ID,
	SC_US_WEEKEND_6_TH_SHIFT_ID: SC_US_WEEKEND_6_TH_SHIFT_ID,
	SC_PR_1_ST_SHIFT_ID: SC_PR_1_ST_SHIFT_ID,
	SC_PR_2_ND_SHIFT_ID: SC_PR_2_ND_SHIFT_ID,
	SC_PR_3_RD_SHIFT_ID: SC_PR_3_RD_SHIFT_ID,
	store: [
		OPENING_SHIFT_ID,
		MORNING_SHIFT_ID,
		MID_SHIFT_SHIFT_ID,
		CLOSING_SHIFT_ID,
		OVERNIGHT_SHIFT_ID
	],
	usWarehouse: [
		SC_US_WEEKDAY_1_ST_SHIFT_ID,
		SC_US_WEEKDAY_2_ND_SHIFT_ID,
		SC_US_WEEKDAY_3_RD_SHIFT_ID,
		SC_US_WEEKEND_4_TH_SHIFT_ID,
		SC_US_WEEKEND_5_TH_SHIFT_ID,
		SC_US_WEEKEND_6_TH_SHIFT_ID,
		FLEX_SHIFT_ID,
	],
	prWarehouse: [SC_PR_1_ST_SHIFT_ID, SC_PR_2_ND_SHIFT_ID, SC_PR_3_RD_SHIFT_ID,FLEX_SHIFT_ID_PR],
};

export const selfScheduleType = {
	SC_ORIENTATION: 'SC_ORIENTATION',
	SC_TOUR: 'SC_TOUR',
	SC_INTERVIEW: 'SC_INTERVIEW',
};

export const Availability = 'availability'